import logo from "../../images/logo.png";
import { useDispatch } from "react-redux";
import { signOut } from "../../redux/actions";

export default function OnboardLayout ({children}) {
  const dispatch = useDispatch();
  
  return (
    <div className="h-full w-full">
      <div className="px-8 w-full h-24 bg-white flex items-center justify-start mb-8">
        <img src={logo} alt="logo" />
        
        <button 
          onClick={() => dispatch(signOut())} 
          className="cursor-pointer" 
        >
          Logout
        </button>
      </div>
      {children}
      <div className="h-4" />
    </div>
  )
}
