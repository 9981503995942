import upgradeImg from '../../images/upgrade.png'


export default function DashboardRight() {
  return (
    <div className='bg-white overflow-hidden pl-4 mr-4 rounded-lg shadow-sm'>
    <div className='flex flex-row justify-between items-center'>
      <div>
        <h2 className='font-bold'>Upgrade to PRO</h2>
        <p className='text-gray-600'>Everything looks <br/>fine down here.</p>
      </div>

      <div className='flex'>
        <img
          src={upgradeImg}
          alt="upgrade"
        />
      </div>
    </div>
  </div>
  )
}