import UserActionTypes from "./user.types";
import axios from "axios";
import { toast } from "react-toastify";
import { LOCAL_STORAGE_KEY } from "../../utils/constants";

export const signInStart = () => ({
  type: UserActionTypes.SIGN_IN_START,
});

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user
});

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error
});

export const signUpStart = () => ({
  type: UserActionTypes.SIGN_UP_START
});

export const signUpSuccess = () => ({
  type: UserActionTypes.SIGN_UP_SUCCESS
});

export const signUpFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_FAILURE,
  payload: error
});

export const updateUserStart = () => ({
  type: UserActionTypes.UPDATE_USER_START
})

export const updateUserSuccess = (user) => ({
  type: UserActionTypes.UPDATE_USER_SUCCESS,
  payload: user
})

export const updateUserFailure = (error) => ({
  type: UserActionTypes.UPDATE_USER_FAILURE,
  payload: error
})

export const clearError = () => ({
  type: UserActionTypes.CLEAR_ERROR,
});

export const getUserProfileStart = () => ({
  type: UserActionTypes.GET_USER_PROFILE_START
});

export const getUserProfileSuccess = (user) => ({
  type: UserActionTypes.GET_USER_PROFILE_SUCCESS,
  payload: user
})

export const getUserProfileFailure = (error) => ({
  type: UserActionTypes.GET_USER_PROFILE_FAILURE,
  payload: error
})


export const signOut = () => function (dispatch) {
  dispatch(signOutStart());
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  dispatch(signOutSuccess());
  window.location.href = "/";
}

export const signIn = (data) => function (dispatch) {
  dispatch(signInStart());
  axios
    .post("/login", data)
    .then((response) => {
      dispatch(signInSuccess(response.data));
      toast.success("Sign in successful!");
      if(!response.data.email_confirmed) {
        window.location.href = '/confirm-email';
      }

      if(response.data.email_confirmed && response.data.is_onboarded) {
        window.location.href = '/dashboard';
      } else {
        window.location.href = '/onboarding/welcome';
      }
    })
    .catch((error) => {
      toast.error("Sign in failed!");
      if(error.response.status === 401) {
        dispatch(signInFailure(error.response?.data?.error));
      } else {
        dispatch(signInFailure("Something went wrong!"));
      }
    });
}

export const signUp = (data) => function (dispatch) {
  dispatch(signUpStart());
  axios
    .post("/users", data)
    .then((response) => {
      dispatch(signUpSuccess(response.data));
      toast.success(response.data.message);
      window.location.href = '/verify-email';
    }).catch((error) => {
      toast.error("Sign up failed!");
      if(error.response.status === 401) {
        dispatch(signUpFailure(error.response?.data?.error));
      } else {
        dispatch(signUpFailure("Something went wrong!"));
      }
    }
  );
}

export const updateUser = (data, slug='') => function (dispatch) {
  dispatch(updateUserStart());
  axios
    .put("/users", data)
    .then((response) => {
      toast.success("Update successful!");
      dispatch(updateUserSuccess(response.data));
      slug && dispatch(userProfile(slug))
    }).catch((error) => {
      if(error.response.status === 401) {
        toast.error(error.response?.data?.error);
        dispatch(updateUserFailure(error.response?.data?.error));
      } else {
        toast.error("Something went wrong!");
        dispatch(updateUserFailure("Something went wrong!"));
      }
    }
  );
}

export const userProfile = (slug) => function (dispatch) {
  dispatch(getUserProfileStart());
  axios
    .get(`/users/${slug}`)
    .then((response) => {
      dispatch(getUserProfileSuccess(response.data));
    }).catch((error) => {
      if(error.response.status === 401) {
        dispatch(getUserProfileFailure(error.response?.data?.error));
        toast.error(error.response?.data?.error);
      } else {
        toast.error("Something went wrong!");
        dispatch(getUserProfileFailure("Something went wrong!"));
      }
      window.history.back();
    }
  );
}