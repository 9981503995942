import logo from '../../images/logo.png';
import blueChat from '../../images/blue_chat.png';
import lightBlue from '../../images/light_blue_chat.png';
import blackChat from '../../images/black_chat.png';

const styles = {
  wrapper: 'flex flex-col lg:flex-row items-center justify-center bg-white ',
  leftSide: 'bg-[#F7FAFC] h-[calc(100vh-4rem)] dark:bg-slate-800 w-full flex flex-col p-8 justify-center items-center hidden lg:flex',
  rightSide: 'w-full h-[calc(100vh-4rem)] p-8 flex flex-col justify-center items-center',
  logo: 'h-[4rem] p-4 flex items-center justify-start self-start bg-white',
  chatTxt: "absolute w-full py-2.5 top-4 md:top-7 inset-x-0  text-center leading-4 text-[1rem] sm:text-xl font-semibold text-white tracking-wider",
}

export default function AuthContainer({children}) {
  return (
    <>
      <div className={styles.logo}>
        <img src={logo} alt='logo' className='p-4 my-4' />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.leftSide}>

        <div className='space-y-10'>
        <div className="relative overflow-hidden">
            <img src={blueChat} alt="blue_chat" className="object-cover w-full h-full" />
            <h4 className={styles.chatTxt}>Get jobs with no experience</h4>
          </div>

          <div className="relative overflow-hidden">
            <img src={lightBlue} alt="blue_chat" className="object-cover w-full h-full" />
            <h4 className={`${styles.chatTxt} text-black`}>Connect with top professionals</h4>
          </div>

          <div className="relative overflow-hidden">
            <img src={blackChat} alt="blue_chat" className="object-cover w-full h-full" />
            <div className={`${styles.chatTxt} tracking-normal leading-5 top-2 md:top-3 text-sm sm:text-lg px-0 sm:px-3`}>
              Recruit or hire entry level job seeker for little stipend or 100% free 
              <div className='flex justify-center items-center text-[#3C3C3C] mt-2 text-[1rem]'>
                <button className='mr-2 bg-white px-2 rounded-lg py-1 font-bold'>Post a GIG</button>
                <button className='bg-white px-2 rounded-lg py-1 font-bold'>Post a Job</button>
              </div>
            </div>
          </div>

          
          <div className='mx-auto text-center w-full'>
          
            <div className='flex w-["80%"] mt-8 justify-center mb-5'>
              <div className='w-1/4 h-[.5rem] mr-4 rounded-md bg-blue-700' />
              <div className='w-1/4 h-[.5rem] mr-4 rounded-md bg-gray-400' />
            </div>
          </div>
        </div>

        </div>

        <div className={styles.rightSide}>
          {children}
        </div>
      </div>
    </>
  )
}
