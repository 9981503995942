import React, { useState, useEffect } from 'react'
import DashboardLayout from '../components/layout/dashboard'
import DashboardLeft from '../components/dashboard/left'
import DashboardPost from '../components/dashboard/post'
import PostCard from '../components/posts/card'
import DashboardRight from '../components/dashboard/right'
import { Navigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { getPosts } from '../redux/actions'
import PreLoader from '../components/loader'

// TODO: Lazy loading on posts

function Dashboard({ fetchPosts }) {
  const {loading, currentUser} = useSelector(state => state.user)
  const { posts } = useSelector(state => state.post)
  const [postItems, setPostItems] = useState([])

  useEffect(() => {
    fetchPosts();
    setPostItems(posts)
  }, [])

  useEffect(() => {
    setPostItems(posts)
  }, [posts])

  const showPostItems = (posts={}, userInfo={}) => {
    if(!posts.length > 0) return;
    
    return (
    posts?.map(post => (
      <React.Fragment key={post.id}>
        <PostCard 
          post={post} 
          user={userInfo}
        />
      </React.Fragment>
    ))
  )}

  if(loading) {
    return <PreLoader />
  }

  if (!currentUser?.is_onboarded) {
    <Navigate to='/onboarding/welcome' />
  }

  return (
    <DashboardLayout>
      <div className='max-w-7xl mx-auto'>
          <div className='flex flex-col sm:flex-row'>
          <div className='w-full md:w-[25%] mb-4'>
            <DashboardLeft userInfo={currentUser} />
          </div>

          <div className='w-full md:w-[65%]  mb-4'>
            <DashboardPost userInfo={currentUser} />
            {showPostItems(postItems, {})}
          </div>

          <div className='w-full md:w-[25%] hidden md:block'>
            <DashboardRight />
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

const mapDispatchToProps = (dispatch) => ({
  fetchPosts: () => dispatch(getPosts()),
})

export default connect(null, mapDispatchToProps)(Dashboard)